import {
  LoginLoginPostApiArg,
  RawApi,
  RegisterRegisterPostApiArg,
  ResetPasswordPasswordPatchApiArg,
} from 'api/openapi/generated/RawApi';
import { CustomApiArg } from 'types/Form';
export const KanopApiTagTypes = [
  'User',
  'ProjectList',
  'Project',
  'fileId',
  'Configuration',
  'Results',
  'DataRequest',
  'Run',
  'OrganizationList',
  'Organization',
  'OrganizationMembers',
  'OrganizationRoles',
  'TreeSpecies',
];
const endpoints: Required<
  Parameters<typeof RawApi.enhanceEndpoints>[0]['endpoints']
> = {
  createNewRunProjectsProjectIdRunsPost: {
    invalidatesTags: ['Run'],
  },
  setRunStatusProjectsProjectIdRunsRunIdPatch: {
    invalidatesTags: ['Run'],
  },
  pingPingGet: {},
  getVersionVersionGet: {},
  getProjectReferencesReferencesGet: {},
  getProjectsGet: {
    providesTags: ['ProjectList'],
  },
  createProjectPost: {
    invalidatesTags: ['ProjectList'],
  },
  getProjectProjectIdGet: {
    providesTags: ['Project'],
  },
  deleteCustomerProjectProjectIdDelete: {
    invalidatesTags: ['ProjectList'],
  },
  patchCustomerProjectProjectIdPatch: {
    invalidatesTags: ['ProjectList'],
  },
  getPolygonsForProjectProjectIdPolygonsGet: {
    providesTags: ['Project', 'Configuration'],
  },
  deletePolygonProjectIdPolygonsDelete: {
    invalidatesTags: ['Project', 'fileId'],
  },
  createPolygonProjectIdPolygonsPost: {
    invalidatesTags: ['Project', 'Configuration'],
  },
  getAggregationLevelForProjectProjectIdAggregationLevelsGet: {
    providesTags: ['Project', 'Configuration'],
  },
  getAggregationLevelValuesForProjectProjectIdAggregationLevelsLevelGet: {
    providesTags: ['Project', 'Configuration'],
  },
  createForestCoverDefinitionConfigurationForProjectProjectIdConfigurationsForestCoverDefinitionPost:
    {
      invalidatesTags: ['Configuration'],
    },
  createAllometricRelationshipConfigurationForProjectProjectIdConfigurationsAllometricRelationshipsPost:
    {
      invalidatesTags: ['Configuration'],
    },
  getConfigurationProjectIdConfigurationsGet: {
    providesTags: ['Configuration'],
  },
  getRequestsProjectIdRequestsGet: {
    providesTags: ['Project', 'DataRequest'],
  },
  createDataRequestProjectIdRequestsPost: {
    invalidatesTags: ['Project', 'DataRequest'],
  },
  getDataRequestProjectIdRequestsRequestIdGet: {
    providesTags: ['DataRequest', 'Run'],
  },
  getSummaryMetricsForRequestProjectIdRequestsRequestIdMetricsGet: {
    providesTags: ['DataRequest', 'Run'],
  },
  getProjectIndicatorsForRequestProjectIdRequestsRequestIdMetricsIndicatorsGet:
    {
      providesTags: ['Project', 'DataRequest', 'Results'],
    },
  getAvailableFilesForRequestProjectIdRequestsRequestIdGisFilesGet: {
    providesTags: ['DataRequest', 'Run'],
  },
  downloadGisFileForRequestProjectIdRequestsRequestIdGisFilesGisFileNameGet: {
    providesTags: ['Project', 'DataRequest', 'Results'],
  },
  getVariationGisFilesProjectIdRequestsRequestIdVariationGisFilesGet: {
    providesTags: ['Project', 'DataRequest', 'Results'],
  },
  registerRegisterPost: {
    invalidatesTags: ['User'],
    query: (queryArg: RegisterRegisterPostApiArg) => ({
      url: `/users/register`,
      method: 'POST',
      body: new URLSearchParams(queryArg.bodyRegisterRegisterPost),
      formData: true,
    }),
  },
  readUsersMeMeGet: {
    providesTags: ['User'],
  },
  loginLoginPost: {
    invalidatesTags: ['User'],
    query: (queryArg: LoginLoginPostApiArg) => ({
      url: `/users/login`,
      method: 'POST',
      body: new URLSearchParams(
        queryArg.bodyLoginLoginPost as unknown as string
      ),
      formData: true,
    }),
  },
  logoutLogoutPut: {
    invalidatesTags: KanopApiTagTypes,
  },
  requestForgotPasswordTokenPasswordForgetPost: {},
  resetPasswordPasswordPatch: {
    invalidatesTags: ['User'],
    query: (queryArg: ResetPasswordPasswordPatchApiArg) => ({
      url: `/users/password`,
      method: 'PATCH',
      body: new URLSearchParams(queryArg.bodyResetPasswordPasswordPatch),
      params: { token: queryArg.token },
      formData: true,
    }),
  },
  uploadGroundTruthsProjectIdFieldMeasurementsPost: {
    invalidatesTags: ['Project'],
    query: (queryArg: CustomApiArg) => ({
      url: `/projects/${queryArg.projectId}/fieldMeasurements`,
      method: 'POST',
      body: queryArg.bodyUploadGroundTruthsProjectIdFieldMeasurementsPost
        .content,
      headers: { 'accept-version': queryArg['accept-version'] },
    }),
  },
  getFieldMeasurementListProjectIdFieldMeasurementsGet: {
    providesTags: ['Project'],
  },
  getApplicationsForUserApplicationsGet: {
    providesTags: ['User'],
  },
  createApplicationForUserApplicationsPost: {
    invalidatesTags: ['User'],
  },
  getApplicationForClientIdApplicationsClientIdGet: {
    providesTags: ['User'],
  },
  deleteApplicationByClientIdApplicationsClientIdDelete: {
    invalidatesTags: ['User'],
  },
  updateApplicationByClientIdApplicationsClientIdPatch: {
    invalidatesTags: ['User'],
  },
  inviteUserToAppInviteesPost: {
    invalidatesTags: ['Organization', 'OrganizationMembers'],
  },
  inviteUserToAppInviteesOptions: {},
  getOrganizationsOrganizationsGet: {
    providesTags: ['OrganizationList'],
  },
  getOrganizationByIdOrganizationsOrganizationIdGet: {
    providesTags: ['Organization'],
  },
  getOrganizationByIdOrganizationsOrganizationIdOptions: {},
  getMembersByOrganizationIdOrganizationsOrganizationIdMembersGet: {
    providesTags: ['OrganizationMembers'],
  },
  deleteMemberOrganizationsOrganizationIdMembersDelete: {
    invalidatesTags: ['OrganizationMembers'],
  },
  deleteMemberOrganizationsOrganizationIdMembersOptions: {},
  getRolesByOrganizationIdOrganizationsOrganizationIdRolesGet: {
    providesTags: ['OrganizationRoles'],
  },
  getRolesByOrganizationIdOrganizationsOrganizationIdRolesOptions: {},
  inviteUserToOrganizationOrganizationsOrganizationIdInviteesPost: {
    invalidatesTags: ['Organization', 'OrganizationMembers'],
  },
  inviteUserToOrganizationOrganizationsOrganizationIdInviteesOptions: {
    invalidatesTags: ['Organization', 'OrganizationMembers'],
  },
  acceptInvitationOrganizationsOrganizationIdAcceptOptions: {
    invalidatesTags: ['Organization', 'OrganizationMembers'],
  },
  activateAccountConfirmGet: {},
  activateAccountConfirmOptions: {},
  createOrganizationOrganizationsPost: {
    invalidatesTags: ['OrganizationList'],
  },
  createOrganizationOrganizationsOptions: {},
  acceptInvitationOrganizationsOrganizationIdAcceptPatch: {
    invalidatesTags: ['Organization', 'OrganizationMembers'],
  },
  getInviteesByOrganizationIdOrganizationsOrganizationIdInviteesGet: {
    providesTags: ['Organization', 'OrganizationMembers'],
  },
  listTreeSpeciesCollectionsCollectionIdTreeSpeciesGet: {
    providesTags: ['TreeSpecies'],
  },
  createTreeSpeciesCollectionsCollectionIdTreeSpeciesPost: {
    invalidatesTags: ['TreeSpecies'],
  },
  changeOrganizationPayingStatusOrganizationsOrganizationIdPatch: {},
  setProjectDetailsProjectsProjectIdPatch: {},
  listJobRequestWorkloadsRequestsGet: {},
  setJobRequestStatusWorkloadsRequestsRequestIdPatch: {},
  createJobForRequestWorkloadsRequestsRequestIdJobsPost: {},
  listJobsWorkloadsRequestsRequestIdJobsGet: {},
  getJobWorkloadsRequestsRequestIdJobsJobIdGet: {},
  setJobStatusWorkloadsRequestsRequestIdJobsJobIdPatch: {},
  createCollectionCollectionsPost: {},
  getCollectionsCollectionsGet: {},
  deleteCollectionsCollectionsDelete: {},
  updateCollectionDetailsCollectionsCollectionIdPatch: {},
  createLandCoverClassesCollectionsCollectionIdLandCoverClassesPost: {},
  listLandCoverClassesCollectionsCollectionIdLandCoverClassesGet: {},
  uploadGtForPlotsCollectionsCollectionIdPlotsPost: {},
  updateGtForPlotsCollectionsCollectionIdPlotsPatch: {},
  getPlotsForCollectionsCollectionsCollectionIdPlotsGet: {},
  deletePlotsCollectionsCollectionIdPlotsDelete: {},
  uploadGtWithFilesCollectionsCollectionIdFilesPost: {},
  listFilesCollectionsCollectionIdFilesGet: {},
  getPlotByIdCollectionsCollectionIdPlotsPlotIdGet: {},
  createRecalibrationCollectionLinkForProjectProjectIdConfigurationsRecalibrationPost:
    {},
  getProjectTsEvolutionIndicatorsProjectIdEvolutionIndicatorsGet: {},
  getProjectTsVariationIndicatorsProjectIdVariationIndicatorsGet: {},
  getProjectTsChangeIndicatorsProjectIdChangeIndicatorsGet: {},
  getStockMetricForPolygonsProjectIdRequestsRequestIdGeoMetricsIndicatorGet: {},
  getChangeMetricForPolygonProjectIdRequestsRequestIdGeoChangeIndicatorGet: {},
  downloadVariationGisFilesProjectIdRequestsRequestIdVariationGisFilesVariationGisFileNameGet:
    {},
  listWebhooksWebhooksGet: {
    providesTags: ['Webhook'],
  },
  createWebhookWebhooksPost: {
    invalidatesTags: ['Webhook'],
  },
  webhookDetailsWebhooksHookIdGet: {
    providesTags: ['Webhook'],
  },
  deleteWebhookWebhooksHookIdDelete: {
    invalidatesTags: ['Webhook'],
  },
  editWebhookWebhooksHookIdPatch: {
    invalidatesTags: ['Webhook'],
  },
  retryWebhookWebhooksHookIdActivityActivityIdPost: {
    invalidatesTags: ['Webhook'],
  },
};

const KanopApi = RawApi.enhanceEndpoints({
  addTagTypes: KanopApiTagTypes,
  endpoints,
});

export default KanopApi;
