import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import KanopApi from 'api/openapi/KanopApi';
import storageReducer from 'store/slices/storage';
import systemReducer from 'store/slices/system';
import authListenerMiddleware from './middleware/authListenerMiddleware';
import systemListenerMiddleware from './middleware/systemListenerMiddleware';

export const store = configureStore({
  reducer: {
    [KanopApi.reducerPath]: KanopApi.reducer,
    system: systemReducer,
    storage: storageReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(KanopApi.middleware)
    .concat(authListenerMiddleware.middleware)
    .concat(systemListenerMiddleware.middleware),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
