import {
  Autocomplete,
  AutocompleteOption,
  Box,
  CircularProgress,
  createFilterOptions,
  ListItemDecorator,
  Stack,
  Typography,
} from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { BiPlus } from 'react-icons/bi';

const iconElements = {
  plus: <BiPlus />,
};
type TreeSpeciesSelectorIcon = keyof typeof iconElements;

export type TreeSpeciesOption = {
  /** The value of the option, must be unique. */
  value: string;
  /** The label of the option. */
  label: string;
  /** The color of the option. */
  color: string;
  /** An icon to display next to the label. */
  icon?: TreeSpeciesSelectorIcon;
};

const filter = createFilterOptions<TreeSpeciesOption>();

export default ({
  value,
  options,
  onChange,
  onOptionChange,
  onOptionCreate,
}: {
  value?: TreeSpeciesOption['value'];
  options: TreeSpeciesOption[];
  onChange: (value: TreeSpeciesOption['value']) => void;
  onOptionChange?: (option: Omit<TreeSpeciesOption, 'value'>) => void;
  onOptionCreate: (
    option: Pick<TreeSpeciesOption, 'label' | 'color'>
  ) => Promise<TreeSpeciesOption['value']>;
}) => {
  const [optionBeingCreated, setOptionBeingCreated] = useState<TreeSpeciesOption | null>(null);

  // Once the parent component has loaded the new option, reset the optionBeingCreated state
  useEffect(() => {
    if (options.some((o) => o.value === optionBeingCreated?.value)) {
      setOptionBeingCreated(null);
    }
  }, [optionBeingCreated?.value, options]);

  /** Create a new option and set it as the selected value. */
  const onClickNewOption = (option: TreeSpeciesOption) => {
    onOptionCreate({
      label: option.value,
      color: option.color,
    }).then((newValue) => {
      setOptionBeingCreated((o) => ({
        color: option.color,
        label: option.value,
        value: newValue,
      }));
      onChange(newValue);
    });
  };

  const currentValueColor = options.find((option) => option.value === value)?.color;

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ width: '100%', marginBottom: '0.8em' }}
    >
      {value !== undefined
        && (optionBeingCreated ? (
          <CircularProgress
            size="sm"
            thickness={2}
            variant="plain"
            color="neutral"
            sx={{
              height: '36px',
              width: '36px',
              '--CircularProgress-size': '1.2rem',
            }}
          />
        ) : (
          <Box
            overflow="hidden"
            sx={{
              borderRadius: '0.4rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              alignSelf: 'stretch',
              width: '28px',
              height: '28px',
              margin: '4px',
              boxShadow: `0 0.2rem 0.75rem 0 color-mix(in srgb, ${currentValueColor}, transparent)`,
              border: '1px solid var(--joy-palette-neutral-outlinedBorder)',
            }}
          >
            <input
              type="color"
              readOnly
              value={currentValueColor}
              style={{
                padding: 0,
                border: 'none',
                margin: '0 -25px',
                transform: 'scale(2)',
                pointerEvents: 'none',
              }}
            />
          </Box>
        ))}
      <Autocomplete
        sx={{ flex: 1, fontSize: '14px' }}
        value={
          optionBeingCreated
          || options.find((option) => option.value === value)
          || null
        }
        options={options}
        placeholder="Tree species"
        onChange={(_, newSelectedOption) => {
          if (newSelectedOption === null) return;
          if (newSelectedOption.icon) {
            setOptionBeingCreated({
              color: newSelectedOption.color,
              label: newSelectedOption.value,
              value: 'new-species',
            });
            onClickNewOption(newSelectedOption);
          } else {
            onChange(newSelectedOption.value);
          }
        }}
        noOptionsText={(
          <Typography level="body-sm">
            Start typing to search or create a new tree species fef
          </Typography>
        )}
        filterOptions={(unfilteredOptions, params) => {
          const filtered = filter(unfilteredOptions, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = unfilteredOptions.some(
            (option) => inputValue === option.label,
          );
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              value: inputValue,
              label: `Add "${inputValue}"`,
              color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
              icon: 'plus',
            });
          }

          return filtered;
        }}
        renderOption={({ ...props }, option) => (
          <AutocompleteOption {...props}>
            {option.icon ? (
              <ListItemDecorator>{iconElements[option.icon]}</ListItemDecorator>
            ) : (
              option.color && (
                <ListItemDecorator>
                  <span
                    style={{
                      backgroundColor: option.color,
                      borderRadius: '1rem',
                      height: '1rem',
                      width: '1rem',
                    }}
                  />
                </ListItemDecorator>
              )
            )}
            {option.label}
          </AutocompleteOption>
        )}
      />
    </Stack>
  );
};
