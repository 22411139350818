import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Input,
  ListItem,
  Stack,
  Typography,
} from '@mui/joy';
import {
  LandCoverClassDetail, LandCoverClassesResponse, PlotGroundTruth, PlotProperties,
} from 'api/openapi/generated/RawApi';
import { GeoJSONFeature } from 'mapbox-gl';
import React, { useEffect, useState } from 'react';
import { CgClose } from 'react-icons/cg';

const LandCoverSelector = (
  {
    formState, setFormState,
    disabled,
    landCover,
    currentFeature,
    landCoverOptions,
    setLandCoverOptions,
    newLandCover,
    setNewLandCover,
    inputValues,
    setInputValues,
    selectedColor,
    setSelectedColor,
    ColorError,
  }:{
      formState: PlotProperties,
      landCover: LandCoverClassesResponse | undefined,
      setFormState: (value: React.SetStateAction<PlotProperties>) => void
      disabled?: boolean,
      refetch: () => void,
      currentFeature: PlotGroundTruth[] | null;
      landCoverOptions: LandCoverClassDetail[],
      setLandCoverOptions: (value: React.SetStateAction<LandCoverClassDetail[]>) => void,
      newLandCover: boolean,
      setNewLandCover: (value: React.SetStateAction<boolean>) => void,
      inputValues: string | undefined,
      setInputValues: (value: React.SetStateAction<string | undefined>) => void,
      selectedColor: LandCoverClassDetail | null,
      setSelectedColor: (value: React.SetStateAction<LandCoverClassDetail | null>) => void,
      ColorError: boolean,
    },
) => {
  const [autoCompleteInput, setAutoCompleteInput] = useState('');

  const [availableLandcoverColors] = useState([
    { color: '#190482' },
    { color: '#C83036' },
    { color: '#F4CE14' },
    { color: '#1E5128' },
    { color: '#E3651D' },
    { color: '#4CAF50' },
    { color: '#5D0E41' },
    { color: '#FFF8DB' },
    { color: '#FF4081' },
  ]);

  useEffect(() => {
    if (landCover?.classes) {
      setLandCoverOptions(landCover.classes.map((lc) => ({
        label: lc.label,
        color: lc.color,
        classId: lc.classId,
        owner: lc.owner,
      })));
    }
  }, [landCover]);

  const handleColorSelect = (option: LandCoverClassDetail) => {
    setSelectedColor(option);
  };

  const switchToCreateLandcover = () => {
    setNewLandCover(true);
    setInputValues(autoCompleteInput);
  };

  return (
    <div className="form-group" id="landCover">
      {newLandCover ? (
        <Box>
          <Stack direction="row" width="auto" spacing={2} alignItems="center">
            <Input
              type="text"
              required
              value={inputValues}
              size="sm"
              onChange={(e) => {
                if (e.target.value === '') {
                  setInputValues(undefined);
                  return;
                }
                setInputValues(e.target.value);
              }}
              placeholder="Enter new land cover class *"
              sx={{ fontSize: '14px', width: '250px' }}
            />
            <Typography
              width="10px"
              onClick={() => {
                setNewLandCover(false);
                setInputValues(undefined);
                setSelectedColor(null);
                setFormState((prev) => ({
                  ...prev,
                  landCoverClassId: undefined,
                }));
              }}
            >
              <CgClose />
            </Typography>
          </Stack>
          <Grid container spacing={1} marginX="auto" marginTop={1}>
            {availableLandcoverColors.map((option) => (
              <Grid key={option.color}>
                <Box
                  sx={{
                    backgroundColor: option.color,
                    cursor: 'pointer',
                    width: selectedColor?.color === option.color ? '18px' : '20px',
                    height: selectedColor?.color === option.color ? '18px' : '20px',
                    borderRadius: '20%',
                    border: selectedColor?.color === option.color ? '3px solid #000' : '1px solid #00000055',
                    // smooth transition for color selection change
                    transition: 'width 0.2s, height 0.2s',
                  }}
                  onClick={() => handleColorSelect((option as LandCoverClassDetail))}
                />
              </Grid>
            ))}
          </Grid>
          {ColorError && (
            <Typography variant="plain" marginBottom={0} paddingBottom={0} color="danger" level="body-sm">
              Make sure a color is selected
            </Typography>
          )}
        </Box>
      ) : (
        <Autocomplete
          options={landCoverOptions}
          disabled={disabled}
          value={landCoverOptions.find(
            (option) => option.classId === formState.landCoverClassId,
          ) || null}
          placeholder="Land cover"
          onChange={(event, newValue) => {
            const updatedLandCoverClassId = newValue?.classId === '' || newValue?.classId === null || newValue?.classId === undefined
              ? undefined
              : (newValue as LandCoverClassDetail).classId;

            // Update form state
            setFormState({
              ...formState,
              landCoverClassId: updatedLandCoverClassId,
            });

            // Retrieve the current plotId
            const plotId = currentFeature?.[0]?.properties?.plotId
            || (currentFeature?.[0] as unknown as GeoJSONFeature)?.id;

            // Retrieve existing session data
            const existingData = sessionStorage.getItem(plotId as string);
            let propertiesToSave;

            if (existingData) {
              const existingProperties = JSON.parse(existingData);

              // Merge existing properties with the updated landCoverClassId
              const mergeProperties = (existing: any, current: any) => {
                const merged = { ...existing };

                Object.keys(current).forEach((key) => {
                  if (current[key] !== null && current[key] !== undefined) {
                    // For numbers: only replace if existing is null or undefined
                    if (typeof current[key] === 'number' && (existing[key] === null || existing[key] === undefined)) {
                      merged[key] = current[key];
                    } else if (typeof current[key] !== 'number') {
                      if (existing[key] === null
                        || existing[key] === undefined || current[key] !== existing[key]) {
                        merged[key] = current[key];
                      }
                    }
                  }
                });

                return merged;
              };

              // Merge the landCoverClassId update into the existing properties
              propertiesToSave = mergeProperties(existingProperties, {
                landCoverClassId: updatedLandCoverClassId,
              });
            } else {
              // If no existing data, create a new object with the landCoverClassId
              propertiesToSave = {
                ...currentFeature?.[0].properties,
                landCoverClassId: updatedLandCoverClassId,
              };
            }

            // Save the updated data back to session storage
            sessionStorage.setItem(plotId as string, JSON.stringify(propertiesToSave));
          }}
          filterOptions={(options, { inputValue }) => {
            setAutoCompleteInput(inputValue);
            const filtered = options.filter(
              (option) => option.label.toLowerCase().includes(inputValue.toLowerCase()),
            );
            if (filtered.length === 0) {
              return [{
                label: 'Add Option',
                color: '',
                classId: '',
                owner: '', // Ensure all required fields are provided
                isButton: true,
              } as LandCoverClassDetail];
            }
            return filtered;
          }}
          sx={{
            width: '100%',
            '& .MuiAutocomplete-inputRoot': {
              padding: '0',
            },
            fontSize: '14px',
          }}
          getOptionLabel={(option) => option.label}
          renderOption={(
            props,
            option: { label: string; color: string | null; isButton?: boolean },
          ) => (
            <ListItem {...props} component="li">
              {option?.isButton ? (
                <Button variant="plain" size="sm" onClick={switchToCreateLandcover}>
                  Add new class +
                  <Typography marginLeft={1} level="body-xs">{autoCompleteInput}</Typography>
                </Button>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      width: 12,
                      height: 12,
                      backgroundColor: option.color || '#000', // default color if none provided
                      marginRight: 1,
                      borderRadius: '30%',
                    }}
                  />
                  <Typography level="body-sm" variant="plain">
                    {option.label}
                  </Typography>
                </Box>
              )}
            </ListItem>
          )}
          noOptionsText={<Box>No options available</Box>}
        />
      )}
    </div>
  );
};

export default LandCoverSelector;
