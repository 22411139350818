import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { RawApi } from 'api/openapi/generated/RawApi';
import { pushError } from 'store/slices/system';
import { clearSession } from './authListenerMiddleware';

export interface SystemError {
  message: string;
  status: number;
  source: string;
}

export const errorSignatures: Record<
  string,
  {
    status: number;
    message: RegExp;
  }
> = {
  invalidCredentials: {
    status: 401,
    message: /Invalid authentication credentials/,
  },
  invalidPrivileges: {
    status: 401,
    message: /require \[(.*)\] found \[(.*)\]/,
  },
};

/**
 * Checks if the error matches the signature.
 * @param error The error object to check.
 * @param signature The signature to check against.
 * @returns `true` if the error matches the signature, `false` otherwise.
 */
const signatureMatch = (
  error: {
    originalStatus: number;
    status: number | string;
    data: {
      detail: string;
    };
  },
  signature: keyof typeof errorSignatures,
) => {
  const { status, message } = errorSignatures[signature];

  const errorStatus = typeof error.status === 'number' ? error.status : error.originalStatus;

  return (
    errorStatus === status
    && (typeof message === 'string'
      ? error?.data?.detail === message
      : message.test(error?.data?.detail))
  );
};

const systemListenerMiddleware = createListenerMiddleware();

// Fetch failure listener
systemListenerMiddleware.startListening({
  matcher: isAnyOf(
    ...Object.values(RawApi.endpoints).map((endpoint) => endpoint.matchRejected),
  ),
  effect: (action, listenerApi) => {
    if (!(typeof action.payload === 'object') || action.payload === null) return;

    const payload = action.payload as Parameters<typeof signatureMatch>[0];
    const endpointName = 'meta' in action
      && typeof action.meta === 'object'
      && action.meta !== null
      && 'arg' in action.meta
      && typeof action.meta.arg === 'object'
      && action.meta.arg !== null
      && 'endpointName' in action.meta.arg
      ? action.meta.arg.endpointName as string
      : 'unknown';

    const status = typeof payload.status === 'number'
      ? payload.status
      : payload.originalStatus;
    const detail = typeof payload?.data === 'string'
      ? payload?.data
      : payload?.data?.detail || undefined;

    if (signatureMatch(payload, 'invalidCredentials')) {
      clearSession(listenerApi);
    } else if (signatureMatch(payload, 'invalidPrivileges')) {
      // set the error as an AuthError
      const [, required, actual] = detail?.match(errorSignatures.invalidPrivileges.message) || [];

      listenerApi.dispatch(
        pushError({
          message: detail || 'Invalid privileges',
          status,
          source: endpointName,
          privilege: {
            required: required.split(', '),
            actual: actual.split(', '),
          },
        }),
      );
    } else {
      // Otherwise, set the error as a SystemError
      listenerApi.dispatch(
        pushError({
          message: detail || 'Unknown error',
          source: endpointName,
          status,
        }),
      );
    }
  },
});

export default systemListenerMiddleware;
