import {
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Tooltip,
} from '@mui/joy';
import {
  FeatureCollectionPlotGroundTruth,
  LandCoverClassesResponse,
  PlotGroundTruth,
  PlotProperties, PlotsResponse,
} from 'api/openapi/generated/RawApi';
import KanopApi from 'api/openapi/KanopApi';
import React from 'react';
import { useParams } from 'react-router-dom';
import { TreeSpeciesOption } from './Inputs/TreeSpeciesSelector';
import PlotList from './Modals/PlotList';
import PopupForm from './Modals/PopUpForm';
import ValidateCollection from './Modals/ValidateCollection';

const GtMapElements = ({
  reviewPlotPopup,
  setReviewPlotPopup,
  opeValidatedPlotsPopup,
  setopeValidatedPlotsPopup,
  plotsData,
  setProperties,
  setOpenFormPopup,
  removeMarker,
  collectionLocked,
  addLayerAndSource,
  setViewSinglePlot,
  viewSinglePlot,
  setZoomOut,
  setMapBbox,
  mapRef,
  drawRef,
  refetch,
  properties,
  setPlotsData,
  currentFeature,
  setCurrentFeature,
  openFormPopup,
  setFeature,
  refetchLandCover,
  landCover,
  setSuccess,
  isUploading,
  handleFinalize,
  polygons,
}: {
  reviewPlotPopup: boolean;
  setReviewPlotPopup: (value: boolean) => void;
  opeValidatedPlotsPopup: boolean;
  setopeValidatedPlotsPopup: (value: boolean) => void;
  plotsData: PlotsResponse | undefined;
  setProperties: (value: PlotProperties | undefined) => void;
  setOpenFormPopup: (value: boolean) => void;
  removeMarker: () => void;
  collectionLocked: boolean;
  addLayerAndSource: (
    map: mapboxgl.Map,
    layerId: string,
    sourceId: string,
    features: PlotGroundTruth,
    geometry: GeoJSON.Geometry
  ) => void;
  setViewSinglePlot: (value: boolean) => void;
  viewSinglePlot: boolean;
  setZoomOut: React.Dispatch<React.SetStateAction<boolean>>;
  setMapBbox: () => void;
  mapRef: mapboxgl.Map | null;
  drawRef: MapboxDraw | null;
  refetch: () => void;
  properties: PlotProperties | undefined;
  setPlotsData: React.Dispatch<React.SetStateAction<PlotsResponse | undefined>>;
  currentFeature: PlotGroundTruth[] | null;
  setCurrentFeature: React.Dispatch<
    React.SetStateAction<PlotGroundTruth[] | null>
  >;
  openFormPopup: boolean;
  setFeature: (value: FeatureCollectionPlotGroundTruth | undefined) => void;
  refetchLandCover: () => void;
  landCover: LandCoverClassesResponse | undefined;
  setSuccess: (value: boolean) => void;
  isUploading: boolean;
  handleFinalize: () => void;
  polygons: (PlotGroundTruth | undefined)[] | undefined;
}) => {
  const { collectionId } = useParams();

  const {
    treeSpeciesOptions,
  } = KanopApi.useListTreeSpeciesCollectionsCollectionIdTreeSpeciesGetQuery({
    collectionId: collectionId as string,
  }, {
    skip: !collectionId,
    selectFromResult: ({ data, ...rest }) => ({
      treeSpeciesOptions: data?.species.map((species) => ({
        value: species.speciesId,
        label: species.label,
        color: species.color,
      })) as TreeSpeciesOption[] || [],
      ...rest,
    }),
  });

  return (
    <Stack
      sx={{
        position: 'absolute',
        top: 0,
        padding: 1,
        right: '10px',
        zIndex: 10,
        color: 'white',
      }}
    >
      <Modal
        open={reviewPlotPopup}
        onClose={() => setReviewPlotPopup(false)}
        disableAutoFocus
      >
        <ModalDialog>
          <ModalClose />
          <PlotList
            allFeatures={polygons}
            map={mapRef}
            setOpen={setReviewPlotPopup}
            plotsData={plotsData}
            draw={drawRef}
            refetch={refetch}
            setproperties={setProperties}
            setOpenFormPopup={setOpenFormPopup}
            removeMarker={removeMarker}
            popUptitle="Ground Truth Plots"
            collectionLocked={collectionLocked}
            addLayerAndSource={addLayerAndSource}
            setViewSinglePlot={setViewSinglePlot}
          />
        </ModalDialog>
      </Modal>
      <Stack width="fit-content" marginLeft="auto">
        <Button
          size="sm"
          variant="solid"
          sx={{
            borderRadius: 'sm',
            marginBottom: 2,
            paddingX: 5,
            backgroundColor: 'white',
            color: '#287769',
            '&:hover': {
              color: 'white',
            },
          }}
          onClick={() => setReviewPlotPopup(true)}
        >
          View data
        </Button>
        <Button
          variant="solid"
          size="sm"
          sx={{
            borderRadius: 'sm',
            paddingX: 5,
            display: collectionLocked ? 'none' : 'inherit',
          }}
          onClick={() => setopeValidatedPlotsPopup(true)}
        >
          Validate
        </Button>
        {viewSinglePlot && (
        <Tooltip
          size="sm"
          variant="plain"
          title="Show all available plots on the map"
          placement="top"
        >
          <Button
            size="sm"
            variant="soft"
            color="neutral"
            sx={{
              width: 'fit-content',
              marginX: 'auto',
              paddingX: 5,
              marginTop: 2,
            }}
            onClick={() => {
              setZoomOut(true);
              setMapBbox();
              setReviewPlotPopup(false);
              setViewSinglePlot(false);
            }}
          >
            View all plots
          </Button>
        </Tooltip>
        )}
      </Stack>
      <ValidateCollection
        opeValidatedPlotsPopup={opeValidatedPlotsPopup}
        plotsData={plotsData}
        setProperties={setProperties}
        setOpenFormPopup={setOpenFormPopup}
        removeMarker={removeMarker}
        collectionLocked={collectionLocked}
        addLayerAndSource={addLayerAndSource}
        setViewSinglePlot={setViewSinglePlot}
        setopeValidatedPlotsPopup={setopeValidatedPlotsPopup}
        polygons={polygons}
        mapRef={mapRef}
        drawRef={drawRef}
        isUploading={isUploading}
        setSuccess={setSuccess}
        handleFinalize={handleFinalize}
      />
      <Stack
        sx={{
          marginTop: 2,
          zIndex: 10,
          color: 'white',
        }}
      >
        {openFormPopup && (
        <PopupForm
          map={mapRef}
          refetchLandCover={refetchLandCover}
          properties={properties}
          draw={drawRef}
          setOpen={setOpenFormPopup}
          setproperties={setProperties}
          setPlotData={setPlotsData}
          setZoomOut={setZoomOut}
          currentFeature={currentFeature}
          collectionLocked={collectionLocked}
          landCover={landCover}
          setCurrentFeature={setCurrentFeature}
          treeSpeciesOptions={treeSpeciesOptions}
          setFeature={
            setFeature as (feature: PlotGroundTruth | undefined) => void
          }
        />
        )}
      </Stack>
    </Stack>
  );
};

export default GtMapElements;
