import {
  Modal, ModalDialog, ModalClose, Stack, Typography, Button,
} from '@mui/joy';
import React from 'react';
import { PlotGroundTruth, PlotProperties, PlotsResponse } from 'api/openapi/generated/RawApi';
import PlotList from './PlotList';

const ValidateCollection = ({
  opeValidatedPlotsPopup,
  plotsData,
  setProperties,
  setOpenFormPopup,
  removeMarker,
  collectionLocked,
  addLayerAndSource,
  setViewSinglePlot,
  polygons,
  mapRef,
  drawRef,
  isUploading,
  setSuccess,
  setopeValidatedPlotsPopup,
  handleFinalize,
}: {
    opeValidatedPlotsPopup: boolean,
    plotsData: PlotsResponse | undefined,
    setProperties: (value: PlotProperties) => void,
    setOpenFormPopup: (value: boolean) => void,
    removeMarker: () => void,
    collectionLocked: boolean,
    addLayerAndSource: (map: mapboxgl.Map,
        layerId: string, sourceId: string,
        features: PlotGroundTruth,
        geometry: GeoJSON.Geometry) => void,
    setViewSinglePlot: (value: boolean) => void,
    polygons: (PlotGroundTruth | undefined)[] | undefined,
    mapRef: mapboxgl.Map | null,
    drawRef: MapboxDraw | null,
    isUploading: boolean,
    setSuccess: (value: boolean) => void,
    setopeValidatedPlotsPopup: (value: boolean) => void,
    handleFinalize: () => void,
}) => (
  <Modal
    open={opeValidatedPlotsPopup}
    onClose={() => {
      setopeValidatedPlotsPopup(false);
      setSuccess(false);
    }}
  >
    <ModalDialog>
      <ModalClose />
      <Stack>
        <PlotList
          allFeatures={polygons}
          map={mapRef}
          setOpen={setopeValidatedPlotsPopup}
          plotsData={plotsData}
          draw={drawRef}
          setproperties={setProperties}
          setOpenFormPopup={setOpenFormPopup}
          removeMarker={removeMarker}
          popUptitle="Ground Truth validation"
          collectionLocked={collectionLocked}
          addLayerAndSource={addLayerAndSource}
          setViewSinglePlot={setViewSinglePlot}
        />
        <Stack
          width="fit-content"
          marginX="auto"
          sx={{
            display: plotsData && plotsData.plots.length < 1 ? 'none' : 'flex',
          }}
        >
          <Stack>
            <Typography level="body-sm" color="danger">
              Once validated, a Ground Truth collection cannot be edited any more.
              The collection will then be prepared and made available to
              recalibrate your project data requests.
            </Typography>
            <Stack>
              <Stack
                direction="row"
                justifyContent="center"
                gap={1}
              >
                <Button
                  onClick={handleFinalize}
                  sx={{
                    backgroundColor: 'success',
                  }}
                  loading={isUploading}
                >
                  Validate
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </ModalDialog>
  </Modal>
);

export default ValidateCollection;
